import { LandingShell } from '../../components/LandingShell';

type LandingLayoutProps = {
  children: React.ReactNode;
};

const LandingLayout = ({ children }: LandingLayoutProps) => (
  <>
    <LandingShell>{children}</LandingShell>
  </>
);

export default LandingLayout;
