import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next';
import { useRouter } from 'next/router';
import { getServerSession } from 'next-auth/next';
import { getProviders, signIn } from 'next-auth/react';

import LandingLayout from '../../global/layouts/LandingLayout';
import { type NextPageWithLayout } from '../_app';
import { authOptions } from '../api/auth/[...nextauth]';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);

  // If the user is already logged in, redirect.
  // NOTE: Make sure not to redirect to the same page to avoid an infinite loop!
  if (session) {
    return { redirect: { destination: '/' } };
  }

  const providers = await getProviders();

  return {
    props: { providers: providers ?? [] },
  };
}

const Signin: NextPageWithLayout<
  InferGetServerSidePropsType<typeof getServerSideProps>
> = ({ providers }) => {
  const router = useRouter();

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <picture>
          <img
            alt="logo"
            className="mx-auto h-40 w-auto"
            src="/logo_dark.svg"
          />
        </picture>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Welcome to CitCom.ai
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <p className="mt-10 text-center text-sm text-gray-500">
          Join our community to browse smart city datasets and use cases that
          are AI ready
        </p>
        <div className="mt-10">
          {router.query.error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-sm mb-10"
              role="alert"
            >
              <strong className="font-bold">Access denied</strong>
              <span>: </span>
              <span className="block sm:inline">
                make sure the your email address has been verified and the
                administrator has granted you access.
              </span>
            </div>
          )}
          {Object.values(providers).map((provider) => (
            <div key={provider.name}>
              <button
                className="flex w-full justify-center rounded-lg bg-black px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                onClick={() => signIn(provider.id)}
              >
                Login / Sign up with {provider.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Signin.getLayout = (page) => {
  return <LandingLayout>{page}</LandingLayout>;
};

export default Signin;
